import { mainnet } from "constants/chains";

export default {
  [`${mainnet.id}`]: {
    Hero: {
      "address": "0xca811cbc503D16A220c5E25Cb8dd435233DbB90A"
    },
    HeroEdition: {
      "address": "0x0BdB2DAdFe82Ff28F5092443cEeEaC55341E392D"
    },
    HeroPresale: {
      "address": "0xc79558468fd13debd1ea7aa1fdf352666e868876"
    },
    // Restaking: "0xa415b3De245d8B616e92A0587954dAF8e8Edb0f5",
    // WETH: "",
    // wstETH: "",
    // ezETH: "",
    // STONE: "",
    // weETH: "",
  },
} as const;
