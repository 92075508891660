import AppAnnouncement from 'components/app/AppAnnouncement';
import { AppFooter } from 'components/app/AppFooter';
import { AppHeader } from 'components/app/AppHeader';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

export const LayoutDefault: React.FC<{ children: React.ReactNode }> = (props) => {
  const location = useLocation();
  return (
    <CptStyles>
      <AppHeader />
      {/* {location.pathname === '/' && <AppAnnouncement />} */}
      {props.children}
      <AppFooter />
    </CptStyles>
  );
};

const CptStyles = styled.div`
  width: 100vw;
`;
